import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthInterceptor } from '../auth.interceptor';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenUrl = environment.authUrl;
  authFailed = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  async fetchToken(): Promise<boolean> {
    console.log(' fetching new token....');
    try {
      const data: any = await this.http.get(this.tokenUrl).toPromise();
      if (data.token) {
        sessionStorage.setItem('bearer', data.token);
        this.authFailed.next(false); 
        return true; //token fetch sucess 
      }
    } catch (error) {
      console.log('error fetching token', error);
      this.authFailed.next(true); //auth failed
    }
    return false; // token fetch failed
  }

  async ensureTokenIsValid(): Promise<boolean> {
    const token = this.getToken();
    if (!token || this.isTokenInvalid(token)) {
      console.warn('Token is expired or missing. fetching a new one..');
      return await this.fetchToken();
    }
    if (this.isTokenInvalid(token)) {
      console.warn('Token is INVALID. Fetching a new one...');
      return await this.fetchToken();
    }

    return true; // token valid
  }

  isTokenInvalid(token: string): boolean {
    // Simulate token validation check
    return token === '401'; // You can improve this logic
  }
  getToken(): string | null {
    return sessionStorage.getItem('bearer')
  }

  logout(): void {
    sessionStorage.removeItem('bearer');
    
  }

  isAuthFailed() {
    return this.authFailed.asObservable();
  }
}
