import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { AuthService } from './services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
 private tokenUrl = environment.authUrl; // Authentication URL
 private excludedUrls = [this.tokenUrl];

 constructor(private authService: AuthService) {}

 intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   // Skip adding Authorization header for token requests
   if (this.excludedUrls.some(url => req.url.includes(url))) {
     console.log(`Skipping Authorization for: ${req.url}`);
     return next.handle(req);
   }
   return from(this.authService.ensureTokenIsValid()).pipe(
    switchMap(() => {
      let token = this.authService.getToken();
      if (token) {
        req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
      }
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          console.warn(`Error detected: ${error.status} - ${error.message}`);
          if (error.status === 401) {
            console.warn('401 Unauthorized detected - Fetching new token...');
            return from(this.authService.fetchToken()).pipe(
              switchMap((token) => {
                if (token) {
                  req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
                  return next.handle(req);
                }
                console.error('Unauthorized - Token refresh failed. Logging out...');
                // this.authService.logout();
                // this.router.navigate(['/login']);
                return throwError(() => new Error('Unauthorized - Token expired or invalid'));
              })
            );
          }
          return throwError(() => error);
        })
      );
    })
  );
}
}
