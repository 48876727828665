

import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
@Component({
 selector: 'app-root',
 templateUrl: './app.component.html',
 styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'eAuditWeb';
 constructor(private authService: AuthService) {
  console.log('tes app component');
  this.authService.fetchToken();
 }

 
}